import React, { useLayoutEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PastProjects, ProjectSlider, UpcomingProjects } from '../components';

const SecondPage = ({ pageContext: { data }, ...props }) => {
  const [query, setQuery] = useState(null);

  const projects_meta_data = data?.meta_data?.data;

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(props?.location?.search);
    const myParam = urlParams.get('section');
    setQuery(myParam);
  }, [props?.location?.search]);

  return (
        <Layout>
            <SEO
                title={projects_meta_data?.title || 'MusicRoom Projects I Past and Upcoming Projects in the Room'}
                showDefaultTitle={false}
                description={projects_meta_data?.description || 'MusicRoom managed to engage more than 90 artists'}
                image={projects_meta_data?.image}
                keywords={projects_meta_data?.keywords}
            />

            <ProjectSlider/>

            <UpcomingProjects/>

            <PastProjects query={query}/>
        </Layout>
  );
};

export default SecondPage;
